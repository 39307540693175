import React, { useState, useRef, useEffect } from 'react'
import dayjs from 'dayjs'
import Resource from './Resource'
import Person from './Person'
import WeekdayComponent from './Weekday'
import { useGlobalState } from '../../store'
import { setRemoteCall } from '../../remote'
import { toast } from 'react-toastify'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isBetween from 'dayjs/plugin/isBetween'
import isoWeek from 'dayjs/plugin/isoWeek'
dayjs.extend(customParseFormat)
dayjs.extend(isBetween)
dayjs.extend(isoWeek)
const columnNames = ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab', 'Dom']

const Boards = props => {
  const [viewOptions, setviewOptions] = useGlobalState('viewOptions')
  const [pickerSource, setPickerSource] = useGlobalState('pickerSource')

  const [projects, setProjects] = useGlobalState('projects')
  const [updateRelations, setUpdateRelations] = useGlobalState(
    'updateRelations'
  )
  const [resources, setResources] = useGlobalState('resources')
  const [relations, setRelations] = useGlobalState('relations')
  const [resourceTypes, setResourceTypes] = useGlobalState('resourceTypes')
  const [allUsers, setUsers] = useGlobalState('users')
  const [userTypes, setUserTypes] = useGlobalState('userTypes')

  useEffect(() => {
    if ((viewOptions && viewOptions.date) || updateRelations) {
      setUpdateRelations(false) //lets stop the update loop
      setRemoteCall('getRelations', viewOptions.date, '', cb => {
        setRelations(cb)
      })
    }
  }, [viewOptions, updateRelations])

  useEffect(() => {
    if (viewOptions && viewOptions.displayType === 'week') {
      if (projects && projects.length > 0) {
        let projectMaxHeight = {}
        setTimeout(() => {
          projects.map(p => {
            let domP = document.getElementsByClassName(p._id)
            Object.keys(domP)
              .map(d => {
                domP[d].style.minHeight = `auto`
                const rect = domP[d].getBoundingClientRect()
                projectMaxHeight[p._id] =
                  (projectMaxHeight[p._id] || 0) < rect.height
                    ? rect.height
                    : projectMaxHeight[p._id]
                return d
              })
              .map(d => {
                domP[d].style.minHeight = `${projectMaxHeight[p._id] + 25}px`
              })
          })
        }, 2000)
      }
    } else {
      setTimeout(() => {
        projects.map(p => {
          let domP = document.getElementsByClassName(p._id)
          Object.keys(domP).map(d => {
            domP[d].style.minHeight = `auto`
          })
        })
      }, 0)
    }
  }, [viewOptions, updateRelations, projects])

  // lets filter users by state
  let users = allUsers.filter(u => u.status < 2)

  /**
   * setting up next and prev props for comparison
   * @param {*} value
   */
  function usePrevious(value) {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  /**
   * Setup initial data
   */
  const currentDate = viewOptions.date //need to update this with controllers
  let searchDate = dayjs(currentDate)
  let items = 1
  if (viewOptions.displayType === 'week') {
    items = 5
    searchDate = dayjs(currentDate).startOf('isoWeek')
  }

  /**
   * 1 .Generate projects and relations hierarchy
   */
  const generateScene = () => {
    return [...Array(items).keys()].map(i => {
      let currDate = searchDate
      if (viewOptions.displayType === 'week') {
        currDate = dayjs(searchDate).add(i, 'days')
      }
      return {
        id: currDate.format('DD/MM/YYYY'),
        type: 'container',
        name: columnNames[currDate.isoWeekday() - 1],
        date: currDate.format('DD/MM/YYYY'),
        children: []
        // children: getSceneProjectsPayload(currDate)
      }
    })
  }

  /**
   * 2. Get nestable projects for each day of the scene
   */
  const getSceneProjectsPayload = today => {
    return (
      projects
        .sort((a, b) => b.priority - a.priority)
        .filter(proj => {
          return dayjs(today, 'DD/MM/YYYY').isBetween(
            dayjs(proj.startDate),
            dayjs(proj.endDate),
            'day',
            '[]'
          )
        })
        .map((project, a) => {
          return {
            id: project._id,
            type: 'container',
            t: project.type,
            name: project.name,
            code: project.code,
            children: getSceneChildResourcesPayload(project._id, null, today)
          }
        }) || []
    )
  }

  /**
   * 3. Get nestable resouces for project scene
   * on the weekend users will only work on special projects
   * so when save the weenkend will only add one day
   * @param {} projectId
   */
  const getSceneChildResourcesPayload = (projectId, accountId, today) => {
    //lets check if resource is available during this dates
    return relations
      .filter(rel => {
        const available = checkPersonAvailability(projectId, today, rel)
        const dateObject = dayjs(today, 'DD/MM/YYYY')
        // we need to clear resources on the weekend
        const weekDay = dateObject.isoWeekday()
        // lets evaluate if its within dates
        if (
          accountId &&
          rel.accountId &&
          rel.projectId &&
          available &&
          rel.accountId.includes(accountId) &&
          rel.projectId.includes(projectId)
        ) {
          if (weekDay === 6 || weekDay === 7) {
            return dayjs(rel.startDate).diff(dateObject, 'days') === 0
          } else {
            // we need to check if we are returning resources and not users!!
            return (
              rel.resourceId &&
              rel.resourceId.length > 0 &&
              dateObject.isBetween(
                dayjs(rel.startDate),
                dayjs(rel.endDate),
                'day',
                '[]'
              )
            )
          }
        } else if (
          rel.projectId &&
          available &&
          rel.projectId.includes(projectId)
        ) {
          if (weekDay === 6 || weekDay === 7) {
            return (
              dayjs(rel.startDate).isSame(dateObject, 'day') &&
              dayjs(rel.endDate).isSame(dateObject, 'day')
            )
          } else {
            return dateObject.isBetween(
              dayjs(rel.startDate),
              dayjs(rel.endDate),
              'day',
              '[]'
            )
          }
        }
      })
      .map(res => {
        //now er are getting the correct resource card
        let cardPayload = {
          relationId: res._id
        }
        if (res.resourceId.length > 0) {
          cardPayload =
            resources.find(card => res.resourceId.includes(card._id)) || {}
          cardPayload.cardType = 'resources'
          cardPayload.author = res.author
          cardPayload.accountId =
            res.accountId && res.accountId.length > 0 ? res.accountId[0] : null
        } else if (res.accountId && res.accountId.length > 0) {
          cardPayload =
            users.find(card => res.accountId.includes(card._id)) || {}
          cardPayload.author = res.author
          cardPayload.cardType = 'users'
        }
        return {
          ...cardPayload,
          relationId: res._id,
          startDate: res.startDate,
          endDate: res.endDate,
          relStatus: res.status
        }
      })
      .sort((a, b) => b.sort - a.sort)
  }

  const checkPersonAvailability = (projectId, date, payload) => {
    let currUser = payload
    //lets only the correct usercard
    if (!payload.availability && !payload.accountId) {
      return true
    }
    // if its a relational card then lets find the correct user card
    if (payload.accountId && payload.accountId.length > 0) {
      currUser = users.find(card => payload.accountId.includes(card._id))
    }
    //user is not available
    if (!currUser) return false
    // user has no availability
    if (!currUser.availability || currUser.availability.length < 1) return true

    //lets check if any of the availability days are matching today
    return (
      currUser &&
      currUser.availability &&
      currUser.availability instanceof Array &&
      !currUser.availability.find(dates => {
        const dateStart = dayjs(dates[0], ['YYYY-MM-DDTHH:mm', 'YYYY-MM-DD'])
        const dateEnd = dayjs(dates[1], ['YYYY-MM-DDTHH:mm', 'YYYY-MM-DD'])
        return dayjs(date, 'DD/MM/YYYY').isBetween(
          dateStart,
          dateEnd,
          'day',
          '[]'
        )
      })
    )
  }

  /**
   * Lets check before dropping card if card can be dropped
   * @param {*} columnId
   * @param {*} cardId
   * @param {*} payload
   */
  const cardCanBeDropped = (columnId, cardId, payload) => {
    //lets get the projects and return if no project is found
    return !getSceneProjectsPayload(columnId).find(project => {
      //letf filter if we fins the same card id in the childreen
      if (payload.source === 'picker') {
        return project.children.find(resource => {
          // return resource._id === payload._id && resource.relStatus === 1
          return resource._id === payload._id
        })
      }
      return false
    })
  }

  /**
   * When we drop a card we need to set it to the project
   * otherwise if its back to resources we need to remove it from the project
   *
   * @param {*} columnId
   * @param {*} cardId
   * @param {*} dropResult
   */
  const onResourceCardDrop = (columnId, cardId, accountId, dropResult) => {
    const { removedIndex, addedIndex, payload } = dropResult
    if (addedIndex !== null) {
      //lets look for duplicates
      if (!cardCanBeDropped(columnId, cardId, payload)) {
        toast.error('Recurso já atribuido neste dia')
        // TODO: NEED TO CHECK IF MULTIRESOURCE
        return null
      }
      //it its a person we need to check if its available
      if (!checkPersonAvailability(cardId, columnId, payload)) {
        toast.error('Recurso não está disponível para esta data')
        return null
      }

      //lets send the request to the backend
      const thisProjects = projects.find(prj => prj._id === cardId)
      setRemoteCall(
        'setRelationToProject',
        {
          dateStart: columnId,
          dateEnd:
            thisProjects && dayjs(thisProjects.endDate).format('DD/MM/YYYY'),
          projectId: cardId,
          accountId: accountId,
          resource: payload,
          sort: addedIndex
        },
        null
      )
    } else if (removedIndex !== null) {
      props.setFromColumn(columnId)
    }
  }

  /**
   * used to prepare data for displat
   * sort, and groups board data
   * @param {*} projectResources
   */
  const getSortedGroups = projectResources => {
    // lets create the groups
    let usedProjectGroups = []
    let sortedProjectResources = []
    const projectResourcesGroup = projectResources.reduce(
      (accumulator, currentValue) => {
        let thisId
        if (currentValue.role && userTypes) {
          const userGroup = userTypes.find(t => currentValue.role === t._id)
          if (userGroup) {
            thisId = userGroup._id
            usedProjectGroups[thisId] = { ...userGroup, type: 'user' }
          }
        } else if (currentValue.type && resourceTypes) {
          const resourceGroup = resourceTypes.find(
            t => currentValue.type === t._id
          )
          if (resourceGroup) {
            thisId = resourceGroup._id
            usedProjectGroups[thisId] = { ...resourceGroup, type: 'resource' }
          }
        }
        if (!accumulator[thisId]) accumulator[thisId] = []
        accumulator[thisId].push(currentValue)
        return accumulator
      },
      {}
    )

    const sortedGroups = Object.keys(projectResourcesGroup)
      .sort((a, b) => {
        if (usedProjectGroups[a] && usedProjectGroups[b]) {
          if (
            usedProjectGroups[a].type !== 'resource' &&
            usedProjectGroups[b].type === 'resource'
          ) {
            return -1
          } else if (
            usedProjectGroups[a].type === 'resource' &&
            usedProjectGroups[b].type !== 'resource'
          ) {
            return 1
          } else {
            return (
              (usedProjectGroups[b].priority || 0) -
              (usedProjectGroups[a].priority || 0)
            )
          }
        }
        return b - a
      })
      .map(group => {
        sortedProjectResources = [
          ...sortedProjectResources,
          ...[group],
          ...projectResourcesGroup[group]
        ]
        return group
      })

    return {
      usedProjectGroups,
      projectResourcesGroup,
      sortedGroups,
      sortedProjectResources
    }
  }

  useEffect(() => {}, [viewOptions])
  /**
   * Render output
   */
  return generateScene().map((column, i) => {
    return (
      <WeekdayComponent
        i={i}
        column={column}
        users={users}
        dayResources={props.dayResources}
        setDayResources={props.setDayResources}
        viewOptions={viewOptions}
        pickerSource={pickerSource}
        // getSceneProjectsPayload={getSceneProjectsPayload}
        projectsPayloadMap={getSceneProjectsPayload(column.id)}
        getSceneChildResourcesPayload={getSceneChildResourcesPayload}
        getSortedGroups={getSortedGroups}
        onClick={props.onClick}
        onResourceCardDrop={onResourceCardDrop}
      />
    )
  })
}

export default Boards
