import React, { useState } from 'react'
import Header from '../components/header'
import Edit from './Edit'
import { Grid, Box, Button } from '@chakra-ui/core'
import { useGlobalState, setGlobalState } from '../store'
import { setRemoteCall } from '../remote'
import TableProvider from '../components/Table/Table'
import Map from '../components/Map/Map'
/**
 * Table collumns
 */
export const tableCollumns = [
  { k: '_id', l: '_id' },
  { k: 'image', l: 'image', t: 'upload' },
  { k: 'nome', l: 'Nome'},
  { k: 'firstName', l: 'Primeiro Nome' },
  { k: 'lastName', l: 'Ultimo Nome' },
  { k: 'code', l: 'Código' },
  { k: 'role', l: 'Tipo' },
  { k: 'email', l: 'Email', t: 'email' },
  { k: 'phone', l: 'Telefone' },
  { k: 'admin', l: 'Administrador' },
  // { k: 'availability', l: 'Disponibilidade', t: 'dateArray' },
  { k: 'details', l: 'Detalhes', t: 'textarea' },
  { k: 'files', l: 'files', t: 'files' },
  { k: 'status', l: 'Estado' },
  { k: 'transport', l: 'Transporte', t: 'transport' },
  { k: 'skills', l: 'Especialidades' },
  { k: 'address', l: 'Morada' },
  { k: 'location', l: 'Localização', t: 'map' }
  // { k: 'attr', l: 'Atributos' },
]

export const typesCollumns = [
  { k: '_id', l: '_id' },
  { k: 'name', l: 'Nome' },
  { k: 'details', l: 'Detalhes', t: 'textarea' },
  { k: 'priority', l: 'Prioridade', t: 'number' },
  { k: 'status', l: 'Estado' },
  { k: 'color', l: 'Cor', t: 'color' }
  // { k: 'attr', l: 'Atributos' },
]

export default function Users(props) {
  /**
   * Get Data
   */
  const [openModal, setOpenModal] = useState(false)
  const [view, setView] = useState('users')
  const [data, setData] = useGlobalState('users')
  const [types, setTypes] = useGlobalState('userTypes')
  //set the initical class name
  setGlobalState('pageClass', 'users')

  const collumns = view === 'users' ? tableCollumns : typesCollumns

  /**
   * Table data
   */
  const users = view === 'users' ? data : types

  /**
   * Table options
   */

  const options = {
    filter: true,
    filterType: 'dropdown',
    count: users ? users.length : 0,
    tableBodyHeight: 'calc( 100vh - 260px )',
    downloadOptions: { filename: 'report.csv', separator: ';' },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      const selectedIds = selectedRows.data.map(
        sel => users[sel.dataIndex] && users[sel.dataIndex]._id
      )

      const actionType = view === 'users' ? 'delUsers' : 'delUserTypes'
      const actionMessage =
        view === 'users' ? ` Deleted users` : ` Deleted user types`

      return (
        <div className="table-actions">
          {/* <Button
            variantColor="red"
            onClick={() =>
              setRemoteCall(actionType, selectedIds, actionMessage)
            }
          >
            Delete Selected
          </Button> */}
        </div>
      )
    },
    onRowClick: (rowData, rowMeta) => {
      setOpenModal(users[rowMeta.dataIndex])
    }
  }

  /**
   * Set table data
   */
  const set = (data, notice) => {
    setRemoteCall(view === 'users' ? 'setUsers' : 'setUserTypes', data, notice)
  }

  return (
    <div className={'table-wrapper'}>
      <Box w="70%" className={'content'} padding={'0 1em'}>
        <Header>
          {/* <button onClick={() => props.socket.emit('page', 'games')}>
          Isto é o que está a acontecer hoje
        </button> */}
          <Grid templateColumns="repeat(2, 1fr)" gap={6}>
            <Box w="100%">
              <h1>Pessoas</h1>
            </Box>

            <Box w="100%" h="10">
              {view === 'users' && (
                <Button
                  active={props.periodType === 'week'}
                  width={'50%'}
                  onClick={() => setView('types')}
                >
                  <span className={`text`}>
                    <span className={`icon-book-open icons`} /> Tipos
                  </span>
                </Button>
              )}
              {view === 'types' && (
                <Button
                  active={props.periodType === 'week'}
                  width={'50%'}
                  onClick={() => setView('users')}
                >
                  <span className={`text`}>
                    {' '}
                    <span className={`icon-people icons`} /> Utilizadores
                  </span>
                </Button>
              )}
              <Button
                active={props.periodType === 'week'}
                variantColor="green"
                width={'49%'}
                marginLeft={'1%'}
                onClick={() => setOpenModal({})}
              >
                <span className={`text`}>
                  <span className={`icon-plus icons`} /> {'Adicionar'}
                </span>
              </Button>
            </Box>
          </Grid>
        </Header>

        {users && (
          <div className={'contentTableMap'}>
            <div className={'tableWrapper'}>
              <TableProvider
                data={users?.map(user => {
                  const modedUser = { ...user, nome: `${user.firstName} ${user.lastName}` }
                  return modedUser
                })}
                columns={collumns.map(key => {
                  if (key.k === 'status') {
                    return {
                      name: key.k,
                      label: key.l,
                      selector: key.k,
                      options: {
                        filter: true,
                        sort: true,
                        customBodyRender: value => {
                          switch (Number(value)) {
                            case 0:
                              return 'Desativo'
                            case 1:
                              return 'Ativo'
                            case 2:
                              return 'Indisponível'
                          }
                        }
                      }
                    }
                  }
                  if (key.k === 'role') {
                    return {
                      name: key.k,
                      label: key.l,
                      selector: key.k,
                      options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                          if (types && value) {
                            const thisType = types.find(t =>
                              value.includes(t._id)
                            )
                            return thisType && thisType.name
                          }
                          return ''
                        }
                      }
                    }
                  }
                  return {
                    name: key.k,
                    label: key.l,
                    selector: key.k,
                    options: {
                      filter: true,
                      sort: true,
                      display:
                        key.k !== '_id' &&
                        key.k !== 'availability' &&
                        key.k !== 'location' &&
                        key.k !== 'files' &&
                        key.k !== 'image' &&
                        key.k !== 'firstName' &&
                        key.k !== 'lastName' &&
                        key.k !== '__v'
                    }
                  }
                })}
                options={options}
              />
            </div>
          </div>
        )}
        {openModal && (
          <Edit
            data={openModal}
            form={collumns.filter(c => c.k !== 'nome')}
            set={set}
            types={types}
            setOpenModal={setOpenModal}
          />
        )}
      </Box>
      <Box w="30%" className={'map'}>
        <Map
          center={users.map(user => {
            const thisType = types.find(type => user.role == type._id)
            return {
              center: user.location,
              name: user.firstName,
              description: user.phone,
              color: thisType ? thisType.color : undefined
            }
          })}
        ></Map>
      </Box>
    </div>
  )
}
